const publication = [
  {
    title: '2024',
    children: [
      {
        title: 'Tissue module discovery in single-cell-resolution spatial transcriptomics data via cell-cell interaction-aware cell embedding',
        img: require('../assets/publition/2024_CellSystems.png'),
        author: 'Yuzhe Li, Jinsong Zhang, Xin Gao, Qiangfeng Cliff Zhang',
        periodical: 'Cell Systems',
        Link: 'https://doi.org/10.1016/j.cels.2024.05.001',
        PDF: 'http://zhanglab.net/files/2024_CellSystems.pdf'
      },
      {
        title: 'Mapping of the influenza A virus genome RNA structure and interactions reveals essential elements of viral replication',
        img: require('../assets/publition/2024_CellReports.png'),
        author: 'Rui Yang, Minglei Pan, Jiamei Guo, Yong Huang, Qiangfeng Cliff Zhang, Tao Deng, Jianwei Wang',
        periodical: 'Cell Reports 43, 113833',
        Link: 'https://doi.org/10.1016/j.celrep.2024.113833',
        PDF: 'http://zhanglab.net/files/2024_CellReports.pdf'
      },
      {
        title: 'Computational prediction and experimental validation identify functionally conserved lncRNAs from zebrafish to human',
        img: require('../assets/publition/2024_NG.png'),
        author: 'Wenze Huang, Tuanlin Xiong, Yuting Zhao, Jian Heng, Ge Han, Pengfei Wang, Zhihua Zhao, Ming Shi, Juan Li, Jiazhen Wang, Yixia Wu, Feng Liu, Jianzhong Jeff Xi, Yangming Wang & Qiangfeng Cliff Zhang',
        periodical: 'Nature Genetics 56, 124–135',
        Link: 'https://www.nature.com/articles/s41588-023-01620-7',
        PDF: 'http://zhanglab.net/files/2024_NG.pdf'
      },
    ]
  },
  {
    title: '2023',
    children: [
      {
        title: 'Pervasive downstream RNA hairpins dynamically dictate start-codon selection',
        img: require('../assets/publition/2023_Nature.png'),
        author: 'Yezi Xiang, Wenze Huang, Lianmei Tan, Tianyuan Chen, Yang He, Patrick S. Irving, Kevin M. Weeks, Qiangfeng Cliff Zhang & Xinnian Dong',
        periodical: 'Nature 621, 423–430',
        Link: 'https://www.nature.com/articles/s41586-023-06500-y',
        PDF: 'http://zhanglab.net/files/2023_Nature.pdf'
      }, 
      {
        title: 'RNA structure determination: From 2D to 3D',
        img: require('../assets/publition/2023_FR.png'),
        author: 'Jie Deng, Xianyang Fang#, Lin Huang#, Shanshan Li, Lilei Xu, Keqiong Ye#, Jinsong Zhang, Kaiming Zhang# and Qiangfeng Cliff Zhang#',
        periodical: 'Fundamental Research',
        Link: 'https://www.sciencedirect.com/science/article/pii/S2667325823001796',
        PDF: 'http://zhanglab.net/files/2023_FR.pdf'
      },  
      {
        title: 'Structural RNA components supervise the sequential DNA cleavage in R2 retrotransposon',
        img: require('../assets/publition/202306_Cell.png'),
        author: 'Pujuan Deng*, Shun-Qing Tan*, Qi-Yu Yang, Han-Zhou Zhu, Lei Sun, Zhangbin Bao, Yi Lin, Qiangfeng Cliff Zhang, Jia Wang# and Jun-Jie Gogo Liu#',
        periodical: 'Cell 186 (13), 2865-2879. e20',
        Link: 'https://www.cell.com/cell/pdf/S0092-8674(23)00584-6.pdf',
        PDF: 'http://zhanglab.net/files/202306_Cell.pdf'
      },  
      {
        title: 'PrismNet: predicting protein–RNA interaction using in vivo RNA structural information',
        img: require('../assets/publition/PrismNet_logo.png'),
        author: 'Yiran Xu*, Jianghui Zhu*, Wenze Huang*, Kui Xu, Rui Yang, Qiangfeng Cliff Zhang# and Lei Sun#',
        periodical: 'Nucleic Acids Research',
        Link: 'https://doi.org/10.1093/nar/gkad353',
        PDF: 'http://zhanglab.net/files/202305_NAR.pdf',
        Server: "http://prismnetweb.zhanglab.net/"
        },
        {
        title: 'Structural basis of membrane skeleton organization in red blood cells',
        img: require('../assets/publition/2023_Cell.png'),
        author: 'Ningning Li, Siyi Chen, Kui Xu, Meng-Ting He, Meng-Qiu Dong, Qiangfeng Cliff Zhang, Ning Gao',
        periodical: ' Cell 186 (9), 1912-1929. e18',
        Link: 'https://www.cell.com/cell/pdf/S0092-8674(23)00278-7.pdf',
        PDF: 'http://zhanglab.net/files/2023_Cell.pdf'
        },
        {
          title: 'CryoRes: Local Resolution Estimation of Cryo-EM Density Maps by Deep Learning',
          img: require('../assets/publition/2023_JMB.png'),
          author: 'Muzhi Dai, Zhuoer Dong, Kui Xu, Qiangfeng Cliff Zhang',
          periodical: 'Journal of Molecular Biology, 168059',
          Link: 'https://doi.org/10.1016/j.jmb.2023.168059',
          PDF: 'http://zhanglab.net/files/2023_JMB.pdf'
          },
    ]
  },
  {
    title: '2022',
    children: [

        {
        title: 'RNA Structural Dynamics Modulate EGFR-TKI Resistance Through Controlling YRDC Translation in NSCLC Cells',
        img: require('../assets/publition/2022_GPB.jpg'),
        author: 'Boyang Shi, Ke An, Yueqin Wang, Yuhan Fei, Caixia Guo, Qiangfeng Cliff Zhang, Yun-Gui Yang, Xin Tian, Quancheng Kan',
        periodical: 'Genomics, Proteomics & Bioinformatics',
        Link: 'https://pubmed.ncbi.nlm.nih.gov/36435452/',
        PDF: 'http://zhanglab.net/files/2022_GPB.pdf'
        },
        
        {
        title: 'Online single-cell data integration through projecting heterogeneous datasets into a common cell-embedding space',
        img: require('../assets/publition/2022_NC2.jpg'),
        author: 'Lei Xiong, Kang Tian, Yuzhe Li, Weixi Ning, Xin Gao, Qiangfeng Cliff Zhang',
        periodical: 'Nature Communications 13 (1), 1-17',
        Link: 'https://www.nature.com/articles/s41467-022-33758-z',
        PDF: 'http://zhanglab.net/files/2022_NC2.pdf'
        },

        {
        title: 'Advances and opportunities in RNA structure experimental determination and computational modeling',
        img: require('../assets/publition/2022_NM.jpg'),
        author: 'Jinsong Zhang, Yuhan Fei, Lei Sun, Qiangfeng Cliff Zhang',
        periodical: 'Nature Methods, 1-15',
        Link: 'https://www.nature.com/articles/s41592-022-01623-y',
        PDF: 'http://zhanglab.net/files/2022_NM.pdf'
        },

        {
        title: 'Differential analysis of RNA structure probing experiments at nucleotide resolution: uncovering regulatory functions of RNA structure',
        img: require('../assets/publition/2022_NC.jpg'),
        author: 'Bo Yu, Pan Li, Qiangfeng Cliff Zhang, Lin Hou',
        periodical: 'Nature communications 13 (1), 1-10',
        Link: 'https://doi.org/10.1038/s41467-022-31875-3',
        PDF: 'http://zhanglab.net/files/2022_NC.pdf'
        },

        {
        title: 'Recent advances in RNA structurome',
        img: require('../assets/publition/2022_SC.jpg'),
        author: 'Bingbing Xu, Yanda Zhu, Changchang Cao, Hao Chen, Qiongli Jin, Guangnan Li, Junfeng Ma, Siwy Ling Yang, Jieyu Zhao, Jianghui Zhu, Yiliang Ding, Xianyang Fang, Yongfeng Jin, Chun Kit Kwok, Aiming Ren, Yue Wan, Zhiye Wang, Yuanchao Xue, Huakun Zhang, Qiangfeng Cliff Zhang, Yu Zhou',
        periodical: 'Science China Life Sciences, 1-40',
        Link: 'https://doi.org/10.1007/s11427-021-2116-2',
        PDF: 'http://zhanglab.net/files/2022_SC.pdf'
        },

        {
        title: 'An ultra low-input method for global RNA structure probing uncovers Regnase-1-mediated regulation in macrophages',
        img: require('../assets/publition/2022_FR.jpg'),
        author: 'Meiling Piao, Pan Li, Xiaomin Zeng, Xiwen Wang, Lan Kang, jinsong Zhang, Yifan Wei, Shaojun Zhang, Lei Tang, Jianghui Zhu, Chun Kit Kwok, Xiaoyu Hu, Qiangfeng Cliff Zhang',
        periodical: 'Fundamental Research 2 (1), 2-13',
        Link: 'https://www.sciencedirect.com/science/article/pii/S2667325821003113',
        PDF: 'http://zhanglab.net/files/2022_FR.pdf'
        },
    ]
  },

  {
    title: '2021',
    children: [

        {
        title: 'A deep learning method for recovering missing signals in transcriptome-wide RNA structure profiles from probing experiments',
        img: require('../assets/publition/2021_NMI.jpg'),
        author: 'Jing Gong*, Kui Xu*, Ziyuan Ma, Zhi John Lu and Qiangfeng Cliff Zhang#',
        periodical: 'Nature Machine Intelligence 3 (11), 995-1006',
        Link: 'https://www.nature.com/articles/s42256-021-00412-0',
        PDF: 'http://zhanglab.net/files/2021_NMI.pdf'
        },

        {
        title: 'Comparison of viral RNA–host protein interactomes across pathogenic RNA viruses informs rapid antiviral drug discovery for SARS-CoV-2',
        img: require('../assets/publition/2021_cellres.jpg'),
        author: 'Shaojun Zhang*, Wenze Huang*, Lili Ren*, Xiaohui Ju*, Mingli Gong*, Jian Rao*, Lei Sun, Pan Li, Qiang Ding#, Jianwei Wang# and Qiangfeng Cliff Zhang#',
        periodical: 'Cell research 32 (1), 9-23',
        Link: 'https://www.nature.com/articles/s41422-021-00581-y',
        PDF: 'http://zhanglab.net/files/2021_CellRes.pdf'
        },

        {
        title: 'The chromatin-remodeling enzyme Smarca5 regulates erythrocyte aggregation via Keap1-Nrf2 signaling',
        img: require('../assets/publition/2021_elife.jpg'),
        author: 'Yanyan Ding*, Yuzhe Li*, Ziqian Zhao*, Qiangfeng Cliff Zhang and Feng Liu#',
        periodical: 'Elife 10, e72557',
        Link: 'https://elifesciences.org/articles/72557',
        PDF: 'http://zhanglab.net/files/2021_elife.pdf'
        },

        {
        title: 'RNA structure probing uncovers RNA structure-dependent biological functions',
        img: require('../assets/publition/2021_NCB.png'),
        author: 'Xiwen Wang*, Chuxiao Liu*, Lingling Chen# and Qiangfeng Cliff Zhang#',
        periodical: 'Nature Chemical Biology 17 (7), 755-766',
        Link: 'https://www.nature.com/articles/s41589-021-00805-7',
        PDF: 'http://zhanglab.net/files/2021_NCB.pdf'
        },
        {
        title: 'RNA structure probing reveals the structural basis of Dicer binding and cleavage',
        img: require('../assets/publition/2021_NC.png'),
        author: 'Qingjun Luo*, Jinsong Zhang*, Pan Li*, Qing Wang, Yue Zhang, Biswajoy Roy-Chaudhuri, Jianpeng Xu, Mark A. Kay#, Qiangfeng Cliff Zhang#',
        periodical: 'Nature communications 12 (1), 1-12',
        Link: 'https://www.nature.com/articles/s41467-021-23607-w',
        PDF: 'http://zhanglab.net/files/2021_NC.pdf'
        },{
        title: 'In vivo structural characterization of the SARS-CoV-2 RNA genome identifies host proteins vulnerable to repurposed drugs',
        img: require('../assets/publition/2021_Cell.png'),
        author: 'Lei Sun*,Pan Li*,Xiaohui Ju*,Jian Rao*,Wenze Huang*,Lili Ren,Shaojun Zhang,Tuanlin Xiong,Kui Xu,Xiaolin Zhou,Mingli Gong,Eric Miska,Qiang Ding#,Jianwei Wang#,Qiangfeng Cliff Zhang#',
        periodical: 'Cell 184 (7), 1865-1883. e20',
        Link: 'https://doi.org/10.1016/j.cell.2021.02.008',
        PDF: 'http://zhanglab.net/files/2021_Cell.pdf'
        },{
        title: 'Predicting dynamic cellular protein–RNA interactions by deep learning using in vivo RNA structures',
        img: require('../assets/publition/2021_CellResearch.png'),
        author: 'Lei Sun*, Kui Xu*, Wenze Huang*, Yucheng T. Yang*, Pan Li, Lei Tang, Tuanlin Xiong, Qiangfeng Cliff Zhang#',
        periodical: 'Cell research 31 (5), 495-516',
        Link: 'https://doi.org/10.1038/s41422-021-00476-y',
        PDF: 'http://zhanglab.net/files/2021_CellResearch.pdf'
        },{
        title: 'Structure of the activated human minor spliceosome',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'Rui Bai*, Ruixue Wan*#, Lin Wang, Kui Xu, Qiangfeng Cliff Zhang, Jianlin Lei, Yigong Shi#',
        periodical: 'Science 371 (6535), eabg0879',
        Link: 'http://doi.org/10.1126/science.abg0879',
        PDF: 'http://zhanglab.net/files/2021_Science.pdf'
       }
    ]
  },
  {
    title: '2020',
    children: [
        {
        title: 'IRIS: A method for predicting in vivo RNA secondary structures using PARIS data',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'Jianyu Zhou, Pan Li, Wanwen Zeng, Wenxiu Ma, Zhipeng Lu, Rui Jiang, Qiangfeng Cliff Zhang#, Tao Jiang#',
        periodical: 'Quantitative Biology 2020, 8(4): 369–381',
        Link: 'https://doi.org/10.1007/s40484-020-0223-4',
        PDF: 'http://zhanglab.net/files/2020_QuantitativeBiology.pdf'
       },{
        title: 'Targeting RNA structures in diseases with small molecules',
        img: require('../assets/publition/202010_review.jpg'),
        author: 'Yanqiu Shao and Qiangfeng Cliff Zhang',
        periodical: 'Essays in Biochemistry 64 (6), 955-966',
        Link: 'https://doi.org/10.1042/EBC20200011',
        PDF: 'http://zhanglab.net/files/202010_EssaysInBiochemistry.pdf'
       },{
        title: 'RASP: an atlas of transcriptome-wide RNA secondary structure probing data',
        img: require('../assets/publition/RASP_logo.png'),
        author: 'Pan Li*, Xiaolin Zhou*, Kui Xu and Qiangfeng Cliff Zhang#',
        periodical: 'Nucleic Acids Research 49 (D1), D183-D191',
        Link: 'https://doi.org/10.1093/nar/gkaa880',
        PDF: 'http://zhanglab.net/files/202010_NAR.pdf',
        Server: "http://rasp.zhanglab.net/"
       },{
        title: 'VRmol: an Integrative Web-Based Virtual Reality System to Explore Macromolecular Structure',
        img: require('../assets/publition/202008.png'),
        author: 'Kui Xu*, Nan Liu*, Jingle Xu, Chunlong Guo, Lingyun Zhao, Hong-Wei Wang, Qiangfeng Cliff Zhang#',
        periodical: 'Bioinformatics 37 (7), 1029-1031',
        Link: 'https://doi.org/10.1093/bioinformatics/btaa696',
        PDF: 'http://zhanglab.net/files/202008_Bioinformatics.pdf',
        Server: "https://VRmol.net",
        Docs: "https://VRmol.net/docs",
        Code: "https://github.com/kuixu/VRmol"
       },
        {
        title: 'RNA structural dynamics regulate early embryogenesis through controlling transcriptome fate and function',
        img: require('../assets/publition/202005.png'),
        author: 'Boyang Shi*, Jinsong Zhang*, Jian Heng*, Jing Gong*, Ting Zhang, Pan Li, Bao-Fa Sun, Ying Yang, Ning Zhang, Yong-Liang Zhao, Hai-Lin Wang, Feng Liu#, Qiangfeng Cliff Zhang# & Yun-Gui Yang#',
        periodical: 'Genome biology 21 (1), 1-27',
        Link: 'https://doi.org/10.1186/s13059-020-02022-2',
        PDF: 'http://zhanglab.net/files/2020_GenomeBiology.pdf'
       },{
        title: 'U1 snRNP regulates chromatin retention of noncoding RNAs',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'Yafei Yin, J. Yuyang Lu, Xuechun Zhang, Wen Shao, Yanhui Xu, Pan Li, Yantao Hong,Li Cui, Ge Shan, Bin Tian, Qiangfeng Cliff Zhang & Xiaohua Shen#',
        periodical: 'Nature 580 (7801), 147-150',
        Link: 'https://doi.org/10.1038/s41586-020-2105-3',
        PDF: 'http://zhanglab.net/files/202003_Nature.pdf'
       },{
        title: 'Keth-seq for transcriptome-wide RNA structure mapping',
        img: require('../assets/publition/202002.png'),
        author: 'Xiaocheng Weng*, Jing Gong*, Yi Chen*, Tong Wu*, Fang Wang, Shixi Yang , Yushu Yuan, Guanzheng Luo, Kai Chen, Lulu Hu, Honghui Ma, Pingluan Wang, Qiangfeng Cliff Zhang#, Xiang Zhou#, Chuan He#',
        periodical: 'Nature chemical biology 16 (5), 489-492',
        Link: 'https://doi.org/10.1038/s41589-019-0459-3',
        PDF: 'http://zhanglab.net/files/20200203_NatureChem.pdf'
       }
    ]
  },
  {
    title: '2019',
    children: [
        {
        title: 'A tRNA-Derived Small RNA Regulates Ribosomal Protein S28 Protein Levels after Translation Initiation in Humans and Mice',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'Hak Kyun Kim, Jianpeng Xu, Kirk Chu, Hyesuk Park, Hagoon Jang, Pan Li, Paul N. Valdmanis, Qiangfeng Cliff Zhang, Mark A. Kay',
        periodical: 'Cell reports 29 (12), 3816-3824. e4',
        Link: 'https://doi.org/10.1016/j.celrep.2019.11.062',
        PDF: 'http://zhanglab.net/files/20191214_CellReports.pdf'
       },
       {
        title: 'icSHAPE-pipe: A comprehensive toolkit for icSHAPE data analysis and evaluation',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'Pan Li, Ruoyao Shi, Qiangfeng Cliff Zhang',
        periodical: 'Methods 178, 96-103',
        Link: 'https://doi.org/10.1016/j.ymeth.2019.09.020',
        PDF: 'http://zhanglab.net/files/20190920_Methods.pdf'
       },
       {
        title: 'SCALE method for single-cell ATAC-seq analysis via latent feature extraction',
        img: require('../assets/publition/201910.png'),
        author: 'Lei Xiong, Kui Xu, Kang Tian, Yanqiu Shao, Lei Tang, Ge Gao, Michael Zhang, Tao Jiang, Qiangfeng Cliff Zhang',
        periodical: 'Nature communications 10 (1), 1-10',
        Link: 'https://www.nature.com/articles/s41467-019-12630-7',
        PDF: 'http://zhanglab.net/files/20191008_NC.pdf'
      },
      {
        title: 'Multivalent m6A motifs promote phase separation of YTHDF proteins',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'Yifei Gao, Gaofeng Pei, Dongxue Li, Ru Li, Yanqiu Shao, Qiangfeng Cliff Zhang and Pilong Li',
        periodical: 'Cell research 29 (9), 767-769',
        Link: 'https://www.nature.com/articles/s41422-019-0210-3',
        PDF: 'http://zhanglab.net/files/20190714_CellResearch.pdf'
      },
      {
        title: 'Mapping In Vivo RNA Structures and Interactions',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'Jieyu Zhao*, Xingyang Qian*, Pui Yan Yeung*, Qiangfeng Cliff Zhang#, and Chun Kit Kwok#',
        periodical: 'Trends in biochemical sciences 44 (6), 555-556',
        Link: 'https://www.cell.com/trends/biochemical-sciences/pdf/S0968-0004(19)30021-0.pdf',
        PDF: 'http://zhanglab.net/files/201906_TrendsBioChem.pdf'
      },
      {
        title: 'RNA structure maps across mammalian cellular compartments',
        img: require('../assets/publition/201902.png'),
        author: 'Lei Sun*, Furqan M. Fazal*, Pan Li*, James P. Broughton, Byron Lee, Lei Tang, Wenze Huang, Eric T. Kool, Howard Y. Chang# and Qiangfeng Cliff Zhang#',
        periodical: 'Nature structural & molecular biology 26 (4), 322-330',
        Link: 'https://www.nature.com/articles/s41594-019-0200-7',
        PDF: 'http://zhanglab.net/files/20190207_NSMB.pdf'
      },
      {
        title: 'A²-Net: Molecular Structure Estimation from Cryo-EM Density Volumes',
        img: require('../assets/publition/a-2-net.png'),
        author: 'Kui Xu, Zhe Wang, Jianping Shi, Hongsheng Li, Qiangfeng Cliff Zhang',
        periodical: 'Proceedings of the AAAI Conference on Artificial Intelligence 33 (01), 1230-1237',
        Link: 'https://aaai.org/ojs/index.php/AAAI/article/view/3918',
        Project: 'http://zhanglab.net/A-2-Net/',
        arXiv: 'https://arxiv.org/abs/1901.00785',
        PDF: 'http://zhanglab.net/files/20190212_AAAI.pdf',
      }
    ]
  },
  {
    title: '2018',
    children: [
      {
        title: 'Investigation of RNA-RNA Interactions Using the RISE ',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'Yanyan Ju*, Jing Gong*, Yucheng T. Yang#, and Qiangfeng Cliff Zhang#',
        periodical: 'Current protocols in bioinformatics 64 (1), e58',
        Link: 'https://currentprotocols.onlinelibrary.wiley.com/doi/full/10.1002/cpbi.58',
        PDF: 'http://zhanglab.net/files/2018_CurrentProtocols.pdf'
      },
      {
        title: 'Integrative Analysis of Zika Virus Genome RNA Structure Reveals Critical Determinants of Viral Infectivity',
        img: require('../assets/publition/20182.png'),
        author: 'Pan Li*, Yifan Wei*, Miao Mei*, Lei Tang*, Lei Sun, Wenze Huang, Jianyu Zhou, Chunlin Zou, Shaojun Zhang, Cheng-Feng Qin, Tao Jiang, Jianfeng Dai, Xu Tan#, and Qiangfeng Cliff Zhang#',
        periodical: 'Cell host & microbe 24 (6), 875-886. e5',
        Link: 'https://www.sciencedirect.com/science/article/pii/S1931312818305493',
        PDF: 'http://zhanglab.net/files/20181212_Cell_HOST&Microbe.pdf'
      },
      {
        title: 'Revealing lncRNA Structures and Interactions by Sequencing-Based Approaches',
        img: require('../assets/publition/20183.jpg'),
        author: 'Xingyang Qian*, Jieyu Zhao*, Pui Yan Yeung*, Qiangfeng Cliff Zhang# and Chun Kit Kwok#',
        periodical: 'Trends in biochemical sciences 44 (1), 33-52',
        Link: 'https://www.sciencedirect.com/science/article/pii/S096800041830197X?via%3Dihub',
        PDF: 'http://zhanglab.net/files/20181117_Review_TiBS.pdf'
      },
      {
        title: 'Advances and challenges towards the study of RNA-RNA interactions in a transcriptome-wide scale',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'Jing Gong*, Yanyan Ju*, Di Shao and Qiangfeng Cliff Zhang',
        periodical: 'Quantitative Biology 6 (3), 239-252',
        Link: 'https://link.springer.com/article/10.1007/s40484-018-0146-5',
        PDF: 'http://zhanglab.net/files/20180502_Review_QB.pdf'
      },
      {
        title: 'Cryo-EM Structure of Human Dicer and Its Complexes with a Pre-miRNA Substrate',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'Zhongmin Liu*, Jia Wang*, Hang Cheng*, Xin Ke*, Lei Sun, Qiangfeng Cliff Zhang, and Hong-Wei Wang',
        periodical: 'Cell 173 (5), 1191-1203. e12',
        Link: 'https://www.cell.com/cell/fulltext/S0092-8674(18)30452-5',
        PDF: 'http://zhanglab.net/files/20180517_Cell.pdf'
      },
      {
        title: 'PARIS: Psoralen Analysis of RNA Interactions and Structures with High Throughput and Resolution',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'Zhipeng Lu, Jing Gong, and Qiangfeng Cliff Zhang',
        periodical: 'RNA Detection, 59-84',
        Link: 'https://link.springer.com/protocol/10.1007%2F978-1-4939-7213-5_4',
        PDF: 'http://zhanglab.net/files/2018_MethMolBiol.pdf'
      },
      {
        title: 'RISE: a database of RNA interactome from sequencing experiments',
        img: require('../assets/publition/20187.png'),
        author: 'Jing Gong*, Di Shao*, Kui Xu, Zhipeng Lu, Zhi John Lu, Yucheng T. Yang#, and Qiangfeng Cliff Zhang#,',
        periodical: 'Nucleic acids research 46 (D1), D194-D201',
        Link: 'https://academic.oup.com/nar/article/46/D1/D194/4344571',
        PDF: 'http://zhanglab.net/files/20180104_NAR.pdf',
        Server: 'http://rise.life.tsinghua.edu.cn/'
      }
    ]
  },
  {
    title: '2017',
    children: [
      {
        title: 'RNA Regulations and Functions Decoded by Transcriptome-wide RNA Structure Probing',
        img: require('../assets/publition/20171.png'),
        author: 'Meiling Piao, Lei Sun, Qiangfeng Cliff Zhang#',
        periodical: 'Genomics, proteomics & bioinformatics 15 (5), 267-278',
        Link: 'https://www.sciencedirect.com/science/article/pii/S1672022917301389?via%3Dihub',
        PDF: 'http://zhanglab.net/files/201710_Review_GPB.pdf'
      },
      {
        title: 'A transfer-RNA-derived small RNA regulates ribosome biogenesis',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'Hak Kyun Kim, Gabriele Fuchs, Shengchun Wang, Wei Wei, Yue Zhang, Hyesuk Park, Biswajoy Roy-Chaudhuri, Pan Li, Jianpeng Xu, Kirk Chu, Feijie Zhang, Mei-Sze Chua, Samuel So, Qiangfeng Cliff Zhang, Peter Sarnow, Mark A. Kay',
        periodical: 'Nature 552 (7683), 57-62',
        Link: 'https://www.nature.com/articles/nature25005',
        PDF: 'http://zhanglab.net/files/20171207_Nature.pdf'
      },
      {
        title: 'RNA editing of SLC22A3 drives early tumor invasion and metastasis in familial esophageal cancer,',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'Li Fu*, Yan-Ru Qin*, Xiao-Yan Ming*, Xian-Bo Zuo*, Yu-Wen Diao*, Li-Yi Zhang, Jiaoyu Ai, Bei-Lei LiuTu-Xiong Huang, Ting-Ting Cao, Bin-Bin Tan, Di Xiang, Chui-Mian Zeng, Jing Gong, Qiangfeng Zhang, Sui-Sui Dong, Juan Chen, Haibo Liu, Jian-Lin Wu, Robert Z. Qi, Dan Xie, Li-Dong Wang, and Xin-Yuan Guand',
        periodical: 'Proceedings of the National Academy of Sciences 114 (23), E4631-E4640',
        Link: 'http://www.pnas.org/content/114/23/E4631',
        PDF: 'http://zhanglab.net/files/20170606_PNAS.pdf'
      }
    ]
  },
  {
    title: '2016',
    children: [
      {
        title: 'RNA Duplex Map in Living Cells Reveals Higher-Order Transcriptome Structure',
        img: require('../assets/publition/2016.png'),
        author: 'Zhipeng Lu*, Qiangfeng Cliff Zhang*, Byron Lee, Ryan A. Flynn, Martin A. Smith, James T. Robinson, Chen Davidovich, Anne R. Gooding, Karen J. Goodrich, John S. Mattick, Jill P. Mesirov, Thomas R. Cech and Howard Y. Chang',
        periodical: 'Cell 165 (5), 1267-1279',
        Link: 'https://www.cell.com/fulltext/S0092867416304226',
        PDF: 'http://zhanglab.net/files/20160512_Cell.pdf'
      }
    ]
  },
  {
    title: 'Before2016',
    children: [
      {
        title: 'Transcriptome-wide interrogation of RNA secondary structure in living cells with icSHAPE',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'R.A. Flynn*, Q.C. Zhang*, R.C. Spitale, B. Lee, M.R. Mumbach, H.Y. Chang',
        periodical: 'Nature protocols 11 (2), 273-290',
        Link: 'https://www.nature.com/articles/nprot.2016.011',
        PDF: 'http://zhanglab.net/files/before_2016/20160114_NatureProtocol.pdf'
      },
      {
        title: 'Rapid evolutionary turnover underlies conserved lncRNA-genome interactions',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'J.J. Quinn*, Q.C. Zhang*, P. Georgiev, I.A. Ilik, A. Akhtar, H.Y. Chang',
        periodical: 'Genes & development 30 (2), 191-207',
        Link: 'http://genesdev.cshlp.org/content/30/2/191.short',
        PDF: 'http://zhanglab.net/files/before_2016/20160917_Genes&Development.pdf'
      },
      {
        title: 'Structural imprints in vivo decode RNA regulatory mechanisms',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'R.C. Spitale*, R.A. Flynn*, Q.C. Zhang*, P. Crisalli, B. Lee, J.W. Jung, H.Y. Kuchelmeister, P.J. Batista, E.A. Torre, E.T. Kool, H.Y. Chang',
        periodical: 'Nature 519 (7544), 486-490',
        Link: 'https://www.nature.com/articles/nature14263',
        PDF: 'http://zhanglab.net/files/before_2016/20150318_Nature.pdf'
      },
      {
        title: 'Systematic discovery of Xist RNA binding proteins',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'C. Chu, Q.C. Zhang, S.T. da Rocha, R.A. Flynn, M. Bharadwaj, J.M. Calabrese, T. Magnuson, E. Heard, H.Y. Chang',
        periodical: 'Cell 161 (2), 404-416',
        Link: 'https://www.sciencedirect.com/science/article/pii/S0092867415003128',
        PDF: 'http://zhanglab.net/files/before_2016/20150409_Cell.pdf'
      },
      {
        title: 'Extensive variation in chromatin states across humans',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'M. Kasowski*, S. Kyriazopoulou-Panagiotopoulou*, F. Grubert*, J.B. Zaugg*, A. Kundaje*, Y. Liu, A.P. Boyle, Q.C. Zhang, F. Zakharia, D.V. Spacek, J. Li, D. Xie, A. Olarerin-George, L.M. Steinmetz, J.B. Hogenesch, M. Kellis, S. Batzoglou, M. Snyder',
        periodical: 'Science 342 (6159), 750-752',
        Link: 'http://science.sciencemag.org/content/342/6159/750',
        PDF: 'http://zhanglab.net/files/before_2016/20131108_Science.pdf'
      },
      {
        title: 'PrePPI: a structure-informed database of protein-protein interactions',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'Q.C. Zhang, D. Petrey, J.I. Garzon, L. Deng, B. Honig',
        periodical: 'Nucleic acids research 41 (D1), D828-D833',
        Link: 'https://academic.oup.com/nar/article/41/D1/D828/1070064',
        PDF: 'http://zhanglab.net/files/before_2016/20121127_NAR.pdf'
      },
      {
        title: 'Structure-based prediction of protein-protein interactions on a genome-wide scale',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'Q.C. Zhang*, D. Petrey*, L. Deng, L. Qiang, Y. Shi, C.A. Thu, B. Bisikirska, C. Lefebvre, D. Accili, T. Hunter, T. Maniatis, A. Califano, B. Honig',
        periodical: 'Nature 490 (7421), 556-560',
        Link: 'https://www.nature.com/articles/nature11503',
        PDF: 'http://zhanglab.net/files/before_2016/20121025_Nature.pdf'
      },
      {
        title: 'PredUs: a web server for predicting protein interfaces using structural neighbors',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'Q.C. Zhang, L. Deng, M. Fisher, J. Guan, B. Honig, D. Petrey',
        periodical: 'Nucleic Acids Research 39 (suppl 2), W283',
        Link: 'https://academic.oup.com/nar/article/39/suppl_2/W283/2505978',
        PDF: 'http://zhanglab.net/files/before_2016/20110523_NAR.pdf'
      },
      {
        title: 'Protein interface conservation across structure space',
        img: require('../assets/publition/EmptyPaper.png'),
        author: 'Q.C. Zhang, D. Petrey, R. Norel, B.H. Honig',
        periodical: 'Proceedings of the National Academy of Sciences 107 (24), 10896-10901',
        Link: 'http://www.pnas.org/content/107/24/10896.long',
        PDF: 'http://zhanglab.net/files/before_2016/20100615_PNAS.pdf'
      }
    ]
  }
]

export default publication
